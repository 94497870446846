import { Link } from "react-router-dom";
import './DataPrivacy.css';
import back_icon from './assets/back_icon.png';

function DataPrivacy() {
  return (
    <div className="data-privacy">
        <Link className="icon-wrapper" to="/">
            <img src={back_icon} className="back-icon" alt="back icon" />
        </Link>
        <h1>Datenschutz</h1>
        <div className="text-block-datenschutz">
        <p>
                Wir nehmen den Schutz personenbezogener Daten sehr ernst. Wir verarbeiten Ihre Daten in Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz personenbezogener Daten, insbesondere der Europäischen Datenschutz-Grundverordnung („DSGVO“) und den für uns geltenden landesspezifischen Ausführungsgesetzen.
                    <br/>
                </p>
                <p>
                I.	Verantwortlicher & Datenschutzbeauftragte des Verantwortlichen:
                    <br/>
                    <br/>
                Verantwortliche Stelle:
                    <br/>
                    <br/>
                PROSTUFF GbR
                    <br/>
                Königinstraße 37, 80539 München
                    <br/>
                E-Mail: kontakt@prostuff.app
                    <br/>
                Internet: https://www.prostuff.app
                    <br/>
                    <br/>
                </p>
                <p>
                II.	Allgemeines
                    <br/>
                    <br/>
                1.	Umfang der Verarbeitung personenbezogener Daten
                Eine Verarbeitung der personenbezogenen Daten unserer Nutzer erfolgt nur insoweit, wie dies zur Bereitstellung einer funktionsfähigen Internetseite und mobile App (nachfolgend Internetseite 'https://www.prostuff.app' und App prostuff) erforderlich und für die Darstellung unserer Inhalte und Leistungen notwendig ist. Die personenbezogenen Daten unserer Nutzer werden regelmäßig nur nach Einwilligung des Nutzers verarbeitet oder in den Fällen, in denen die Verarbeitung durch eine gesetzliche Vorschrift gestattet ist und damit eine Rechtsgrundlage für die Verarbeitung besteht.
                    <br/>
                    <br/>
                2.	Rechtsgrundlagen für die Verarbeitung personenbezogener Daten
                Sofern nicht gesondert im Rahmen dieser Datenschutzerklärung benannt, gilt Folgendes -Rechtsgrundlage
                    <br/>
                ▪	bei Vorliegen einer Einwilligung ist Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO).
                    <br/>
                ▪	zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, Art. 6 Abs. 1 lit. b DSGVO. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                    <br/>
                ▪	zur Erfüllung einer rechtlichen Verpflichtung, der wir unterliegen, ist Art. 6 Abs. 1 lit. c DSGVO.
                    <br/>
                ▪	bei lebenswichtigen Interessen der betroffenen Person oder einer anderen natürlichen Person, ist Art. 6 Abs. 1 lit. d DSGVO.
                    <br/>
                ▪	zur Wahrung unseres berechtigten Interesses oder das eines Dritten, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht überwiegen, ist Art. 6 Abs. 1 lit. f DSGVO.
                    <br/>
                    <br/>
                3.	Datenlöschung und Speicherdauer
                Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, so werden personenbezogene Daten der betroffenen Person gelöscht oder gesperrt sobald der einer Speicherung zugrundeliegende Zweck entfällt. Über diesen Zeitpunkt hinaus kann eine Speicherung dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Es können Aufbewahrungs- und Dokumentationspflichten - etwa aus dem Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) – die eine Pflicht zur Aufbewahrung bzw. Dokumentation von bis zehn Jahre über das Ende der Geschäftsbeziehung bzw. des vorvertraglichen Rechtsverhältnisses hinaus vorsehen, bestehen.
                    <br/>
                    <br/>
                4.	SSL- bzw. TLS-Verschlüsselung
                Wir nutzen auf unserer Website aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte (z.B. Anfragen), die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung ist daran zu erkennen, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in der Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können an uns übermittelte Daten, nicht von Dritten mitgelesen werden.
                    <br/>
                    <br/>
                </p>
                <p>
                III.	Bereitstellung der Website und Erstellung von Logfiles
                    <br/>
                    <br/>
                1.	Beschreibung und Umfang der Datenverarbeitung
                Wird unsere Website von einem Nutzer aufgerufen, so erfasst unser System, mit jedem Aufruf, automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Hierzu gehören standardmäßig: IP-Adresse, Datum und Uhrzeit der Anfrage, Zeitzonendifferenz zur Greenwich Mean Time (GMT), Inhalt der Anforderung (konkrete Seite), Zugriffsstatus/HTTP-Statuscode, jeweils übertragene Datenmenge, Website, von der die Anforderung kommt, Browser, Betriebssystem und dessen Oberfläche, Sprache und Version der Browsersoftware.
                Diese Daten werden in den Logfiles gespeichert. Es findet keine gemeinsame Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers statt.
                    <br/>
                    <br/>
                2.	Zweck und Rechtsgrundlage
                Wir verarbeiten die genannten Daten zu folgenden Zwecken:
                Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, Gewährleistung einer komfortablen Nutzung unserer Website, Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken. Rückschlüsse auf den Nutzer werden in keinem Fall gezogen. In den zuvor aufgeführten Zwecken liegt unser berechtigtes Interesse, Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.
                    <br/>
                    <br/>
                3.	Dauer der Speicherung
                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Bei der Erfassung der Daten zur Bereitstellung der Webseite ist dies der Fall, wenn die jeweilige Sitzung beendet ist, d.h. wenn Sie unsere Webseite verlassen.
                    <br/>
                    <br/>
                4.	Widerspruchs- und Beseitigungsmöglichkeit
                Für den Betrieb und die Bereitstellung der Website ist die Erfassung der Daten und deren Speicherung in Logfiles zwingend erforderlich. Eine Widerspruchsmöglichkeit besteht seitens des Nutzers nicht.
                    <br/>
                    <br/>
                </p>
                <p>
                IV.	Cookies
                    <br/>
                    <br/>
                1. Beschreibung und Umfang der Datenverarbeitung
                Wir setzen auf unserer Website sog. Cookies ein. Cookies sind kleine Textdateien, die auf dem Endgerät des Nutzers abgelegt werden, d.h. sie werden im Internetbrowser des Nutzers gespeichert, wenn der Nutzer die Website aufruft. Wir setzen die folgenden Arten von Cookies ein:
                a)	Transiente Cookies (temporärer Einsatz)
                Transiente Cookies werden automatisiert gelöscht, wenn der Nutzer den Browser schließt. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Session-Cookies werden gelöscht, wenn sich der Nutzer ausloggt oder den Browser schließt.
                b)	Persistente Cookies (zeitlich beschränkter Einsatz)
                Diese Cookies unterscheiden sich von den Transienten Cookies lediglich dadurch, dass sie beim Schließen des Browsers nicht automatisch gelöscht werden, sondern erst nach einer voreingestellten Zeit. Nutzer können diese Cookies jedoch jederzeit über die Einstellungen ihres Browsers löschen.
                    <br/>
                    <br/>
                2. Zweck und Rechtsgrundlage
                Die von uns eingesetzten Cookies haben den Zweck unsere Website nutzerfreundlicher zu machen und die Bedienung zu vereinfachen. Eine Widererkennung für diese Funktionen ist erforderlich, damit der Browser des Nutzers auch nach einem Seitenwechsel wiedererkannt wird und auch die technische Verfügbarkeit so sichergestellt werden kann. Anderenfalls können Funktionen unserer Website ohne Cookies unter Umständen nicht angeboten werden. Eine Erstellung von Nutzerprofilen durch die, mittels notwendigen Cookies erhobenen Daten von Nutzern, erfolgt nicht.
                Des Weiteren dienen die von uns eingesetzten Cookies dazu die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Google Analytics). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
                Unser berechtigtes Interesse am Einsatz dieser Cookies liegt in den zuvor beschriebenen Zwecken. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.
                    <br/>
                    <br/>
                3. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
                Nutzer, die nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, können die entsprechende Option in den Systemeinstellungen ihres Browsers deaktivieren oder bereits gespeicherte Cookies löschen. Dies kann auch automatisiert erfolgen. Die Deaktivierung von Cookies kann zu Funktionseinschränkungen unseres Angebots führen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Anbei einige Links für gängige Browser:
                Internet Explorer: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
                Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                Chrome: http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                Safari: https://support.apple.com/kb/ph21411?locale=de_DE
                Opera: 'http://help.opera.com/Windows/10.20/de/cookies.html'
                    <br/>
                    <br/>
                </p>
                <p>
                V.	Kontaktaufnahme per E-Mail
                    <br/>
                    <br/>
                1.	Beschreibung und Umfang der Datenverarbeitung
                Nutzer können uns über die bereitgestellte E-Mail-Adresse elektronisch kontaktieren. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.
                    <br/>
                    <br/>
                2.	Zweck und Rechtsgrundlage
                Wir verwenden die in der jeweiligen E-Mail gemachten Angaben und Daten ausschließlich für die Bearbeitung der Kontaktaufnahme/Anfrage und zur Führung der damit verbundenen Kommunikation. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.
                Rechtsgrundlage bei Vorliegen einer Einwilligung des Nutzers, ist Art. 6 Abs. 1 lit. a DSGVO.
                Rechtsgrundlage für die Kontaktaufnahme per E-Mail, ist Art. 6 Abs. 1 lit. f DSGVO.
                Ist das Ziel der Kontaktaufnahme per E-Mail der Vertragsschluss mit dem Verantwortlichen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
                    <br/>
                    <br/>
                3.	Dauer der Speicherung
                Personenbezogene Daten, die per E-Mail übermittelt wurden, werden gelöscht, wenn die jeweilige Kommunikation mit dem Nutzer abgeschlossen ist. Die Kommunikation wird zu dem Zeitpunkt als abgeschlossen betrachtet, wenn der Grund oder der der Sachverhalt, der der Anfrage/Kontaktaufnahme zugrunde liegt, abschließend beantwortet und geklärt wurde.
                    <br/>
                    <br/>
                4.	Widerspruchs- und Beseitigungsmöglichkeit
                Der Nutzer kann seine Einwilligung zur Verarbeitung der personenbezogenen Daten widerrufen. Sofern die Kontaktaufnahme per E-Mail stattfindet, so kann der Nutzer jederzeit der Speicherung seiner personenbezogenen Daten widersprechen. Die Kommunikation mit dem Nutzer kann dann nicht weitergeführt werden. Der Widerruf kann an die unter I. aufgeführten Kontaktdaten des Verantwortlichen erfolgen oder per E-Mail an kontakt@prostuff.app.
                In diesem Fall erfolgt die Löschung der personenbezogenen Daten, die in diesem Zusammenhang gespeichert wurden.
                    <br/>
                    <br/>
                </p>
                <p>
                VI.	Verarbeitung personenbezogener Daten unserer Geschäftspartner
                    <br/>
                    <br/>
                1.	Zwecke der Datenverarbeitung
                Im Rahmen der Zusammenarbeit mit unseren gegenwärtigen und zukünftigen Kunden, Lieferanten und Partnern („Geschäftspartner“) verarbeitet PROSTUFF GbR personenbezogene Daten der jeweiligen Kontaktpersonen für die folgenden Zwecke:
                •	Kommunikation zu Produkten, Dienstleistungen und Projekten, z.B. um Anfragen des Geschäftspartners zu bearbeiten;
                •	Planung, Durchführung und Verwaltung der (vertraglichen) Geschäftsbeziehung zwischen uns und dem Geschäftspartner, z.B. um die Bestellung von Produkten und Dienstleistungen abzuwickeln, Zahlungen einzuziehen, zu Zwecken der Buchhaltung, Abrechnung und des Forderungseinzugs und um Lieferungen oder Wartungstätigkeiten durchzuführen;
                •	Durchführung von Kundenbefragungen, Marketingkampagnen, Marktanalysen, Wettbewerben oder ähnlichen Aktionen und Events;
                •	Einhaltung von rechtlichen Anforderungen (z.B. von steuer- und handelsrechtlichen Aufbewahrungspflichten), und
                •	Beilegung von Rechtsstreitigkeiten, Durchsetzung bestehender Verträge und zur Geltendmachung, Ausübung und Verteidigung von Rechtsansprüchen.
                    <br/>
                    <br/>
                2.	Datenkategorien
                Für die vorgenannten Zwecke verarbeitet PROSTUFF GbR gegebenenfalls die folgenden Kategorien personenbezogener Daten:
                •	Kontaktinformationen, wie Vor- und Zunamen, geschäftliche Anschrift, geschäftliche Telefonnummer, geschäftliche Mobilfunknummer, geschäftliche Faxnummer und geschäftliche E-Mail-Adresse;
                •	Zahlungsdaten, wie Angaben die zur Abwicklung von Zahlungsvorgängen oder Betrugsprävention erforderlich sind, einschließlich damit zusammenhängende Abrechnungsinformationen;
                •	Weitere Informationen, deren Verarbeitung im Rahmen eines Projekts oder der Abwicklung einer Vertragsbeziehung mit PROSTUFF GbR erforderlich sind oder die freiwillig von unseren Ansprechpartnern angegeben werden, wie getätigte Bestellungen/Aufträge, getätigte Anfragen oder Projektdetails;
                •	Informationen, die aus öffentlich verfügbaren Quellen, Informationsdatenbanken oder von Auskunfteien erhoben werden.
                    <br/>
                    <br/>
                3.	Rechtsgrundlagen
                Rechtsgrundlagen für die Datenverarbeitung ergeben sich insbesondere aus Art. 6 DSGVO. Danach dürfen wir Ihre personenbezogenen Daten verarbeiten, wenn:
                •	die Verarbeitung für die Erfüllung des mit uns geschlossenen Vertrages erforderlich ist (Art. 6 Abs. 1 lit. b) DSGVO);
                •	wir gesetzlich zur Bearbeitung verpflichtet sind, z.B. zur Einhaltung der Aufbewahrungsfristen gegenüber dem Finanzamt (Art. 6 Abs. 1 lit. c) DSGVO);
                •	die Verarbeitung zum Zwecke der berechtigten geschäftlichen Interessen, die von uns oder einem Dritten verfolgt werden, erforderlich ist, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern überwiegen (Art. 6 Abs. 1 lit. f) DSGVO) oder
                •	Sie der Verarbeitung Ihrer personenbezogenen Daten für einen oder mehrere Zwecke zugestimmt haben (Art. 6 Abs. 1 lit. a) DSGVO).
                Soweit wir beabsichtigen, die Datenverarbeitung auf Ihre Einwilligung zu stützen, klären wir Sie über die Zwecke der Datenverarbeitung und über Ihr Widerrufsrecht auf.
                    <br/>
                    <br/>
                </p>
                <p>
                VII.	Empfänger von Daten oder Kategorien von Empfängern
                    <br/>
                    <br/>
                Eine interne Weitergabe von Daten erfolgt nur an diejenigen Stellen bzw. Organisationseinheiten, die diese zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder im Rahmen der Bearbeitung und Umsetzung unseres berechtigten Interesses benötigen.
                Eine Weitergabe Ihrer Daten an externe Stellen kann in den folgenden Fällen erfolgen:
                •	Übermittlung an IT-Dienstleister, die Daten im Rahmen Ihrer Leistungserbringung verarbeiten (wie z.B. Dienstleister für IT Wartungstätigkeiten oder - Supportservices). Soweit Dienstleister im Rahmen einer Auftragsverarbeitung von uns beauftragt werden, unterliegen Ihre Daten dort den gleichen Sicherheitsstandards wie bei uns. Das Hosting unserer Website erfolgt durch Amazon Webservices (AWS) in einem Data Center in Frankfurt, Main Deutschland. Eine Vereinbarung über die Verarbeitung personenbezogener Daten im Auftrag wurde geschlossen, oder
                •	an Gerichte, Schiedsgerichte, Behörden oder Rechtsberater, wenn dies zur Einhaltung geltenden Rechts, oder zu Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist oder die Datenweitergabe im öffentlichen Interesse liegt.
                •	wenn Sie uns eine Einwilligung zur Übermittlung an Dritte gegeben haben.
                    <br/>
                    <br/>
                </p>
                <p>
                VIII.	Einsatz und Verwendung von Google Analytics
                    <br/>
                    <br/>
                Wir nutzen den Webanalysedienst Google Analytics des Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Bitte beachten Sie, dass der Google Analytics-Code auf unserer Website um "gat._anonymizeIp ();" ergänzt wird, um eine pseudonomisierte Verarbeitung von IP-Adressen (sog. IP-Masking) zu gewährleisten. Dadurch wird Ihre IP-Adresse von Google jedoch innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an die Server von Google in den USA übertragen und dort gekürzt.
                Google benutzt diese Informationen in unserem Auftrag, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für uns zusammenzustellen und um für uns weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Google wird Ihre IP-Adresse nicht mit anderen Daten von Google in Verbindung bringen. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
                Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                Ein Vertrag zur Auftragsdatenverarbeitung wurde mit Google abgeschlossen.
                Die Erhebung und Nutzung Ihrer Daten durch Google Daten (Cookies und IP-Adresse) können Sie verhindern, indem Sie das unter https://tools.google.com/dlpage/gaoptout?hl=de-DE verfügbare Browser-Plug-In herunterladen und installieren.
                Widerspruch gegen Datenerfassung: Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.
                Speicherdauer: Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter diesem Link.
                Weitere Informationen zu den Nutzungsbedingungen und zum Datenschutz finden Sie unter: 'http://www.google.com/analytics/terms/de.html http://www.google.com/intl/de/analytics/privacyoverview.html.'
                    <br/>
                    <br/>
                </p>
                <p>
                IX.	 (Externe) Links zu anderen Websites, Soziale Netzwerke & Partnerprogramme
                    <br/>
                    <br/>
                1.	(Externe) Links
                Wir weisen darauf hin, dass unsere Website weitere Verlinkungen auf Internet-Auftritte anderer Unternehmen enthalten kann auf deren Inhalte wir keinen Einfluss haben Für die Inhalte dieser Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    <br/>
                    <br/>
                2.	Soziale Netzwerke
                Wir unterhalten des Weiteren Präsenzen in verschiedenen sozialen Medien, welche Sie über entsprechende Schaltflächen einer Webseite erreichen können. Beim Besuch einer solchen Präsenz, werden ggf. personenbezogene Daten an den Anbieter des sozialen Netzwerks übermittelt. Es besteht die Möglichkeit, dass neben der Speicherung der von Ihnen in diesem sozialen Medium konkret eingegebenen Daten auch weitere Informationen vom Anbieter des sozialen Netzwerks verarbeitet werden.
                Überdies verarbeitet der Anbieter des sozialen Netzwerks ggf. die wichtigsten Daten des Computersystems, von dem aus Sie dieses besuchen – zum Beispiel Ihre IP-Adresse, den genutzten Prozessortyp und Browserversion samt Plug-ins. Sind Sie während des Besuchs einer solchen Präsenz mit Ihrem persönlichen Benutzerkonto des jeweiligen Netzwerkes eingeloggt sind, so kann dieses Netzwerk den Besuch diesem Konto zuordnen.
                Zweck und Umfang der Datenerhebung sowie die dortige weitere Verarbeitung Ihrer Daten wie auch Ihre diesbezüglichen Rechte entnehmen Sie bitte den jeweiligen Bestimmungen des Verantwortlichen:
                    <br/>
                Instagram:	'https://help.instagram.com/155833707900388'
                    <br/>
                Facebook: 	de-de.facebook.com/about/privacy/
                    <br/>
                    <br/>
                3.	Soziale Netzwerke im Einzelnen
                Facebook
                Wir verfügen über ein Profil und eine Fanpage bei Facebook - Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA. Wir haben eine Vereinbarung über eine gemeinsame Verantwortlichkeit bei der Verarbeitung von Daten (Controller Addendum) geschlossen. In dieser Vereinbarung mit Facebook ist festgelegt, für welche Datenverarbeitungsvorgänge wir bzw. Facebook verantwortlich ist, wenn Sie unsere Facebook- Fanpage besuchen. Informationen zur Vereinbarung finden Sie unter:
                    <br/>
                    <br/>
                    {' '}
                'https://www.facebook.com/legal/terms/page_controller_addendum'
                    <br/>
                Werbeeinstellungen können Sie in Ihrem Nutzer-Account anpassen:
                    <br/>
                `https://www.facebook.com/settings?tab=ads`
                    <br/>
                Die Datenschutzerklärung von Facebook finden Sie hier: `https://www.facebook.com/about/privacy/`
                    <br/>
                Facebook verfügt über eine EU-US-Privacy-Shield-Zertifizierung.
                    <br/>
                    <br/>
                4.	Amazon Partnerprogramm
                Wir nehmen am Amazon EU- Partnerprogramm der Amazon EU S.à.r.l, 5 Rue Plaetis, L-2338 Luxembourg, Luxemburg teil. Auf unseren Webseiten werden Werbeanzeigen und Links zur Seite von Amazon.de durch Amazon eingebunden, an denen über Werbekostenerstattung eine Vergütung an uns erfolgen kann. Hierzu setzt Amazon Cookies ein, um die Herkunft der getätigten Bestellungen nachvollziehen zu können. Amazon kann dadurch erkennen, dass ein Nutzer den Partnerlink auf unserer Website geklickt hat, nur so ist die die Höhe der sich ergebenden Affiliate-Vergütung feststellbar. Rechtsgrundlage ist Art 6 Abs.1 lit.f. DSGVO. Das berechtigte Interesse besteht im Interesse am wirtschaftlichen Betrieb des Onlineangebots wie in den zuvor genannten Zwecken beschrieben.
                Das Setzen von Cookies durch unsere Website kann, wie oben bereits dargestellt, jederzeit mittels entsprechender Einstellung des genutzten Internetbrowsers durch den Nutzer verhindert werden - Sie können damit der Setzung von Cookies dauerhaft widersprechen.
                Informationen zur Datennutzung durch Amazon erhalten Sie in der Datenschutzerklärung von Amazon: 'https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401.'
                    <br/>
                    <br/>
                </p>
                <p>
                X.	Datenschutzhinweise für Bewerberinnen und Bewerber
                    <br/>
                    <br/>
                Wir freuen uns, dass Sie sich für uns als Arbeitgeber interessieren. In diesem Abschnitt erhalten Sie Informationen zur Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit einer Bewerbung.
                    <br/>
                    <br/>
                1.	Zweck und Rechtsgrundlage der Verarbeitung personenbezogener Daten im Rahmen von Bewerbungen
                Wir verarbeiten die Daten, die Sie uns im Zusammenhang mit Ihrer Bewerbung zugesendet haben, um Ihre Eignung für die jeweilige Stelle (oder ggf. andere offene Positionen in unseren Unternehmen) zu prüfen und das Bewerbungsverfahren durchzuführen.
                Rechtsgrundlage hierfür ist § 26 BDSG in der ab dem 25.05.2018 geltenden Fassung (BDSG neu). Danach ist die Verarbeitung von Daten zulässig, wenn dies für die Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich ist.
                Des Weiteren kann die Verarbeitung von Daten nach Abschluss des Bewerbungsverfahrens ggf. zur Rechtsverfolgung bzw. zur Abwehr von vom Bewerber geltend gemachten Ansprüchen erforderlich sein. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO, wonach eine Datenverarbeitung aufgrund berechtigten Interesses erfolgen darf. Unser berechtigtes Interesse kann beispielsweise in der Geltendmachung oder der Abwehr von Ansprüchen bestehen oder etwa in der Verpflichtung zur Beweisführung in einem Verfahren nach Allgemeinem Gleichbehandlungsgesetz (AGG) begründet sein.
                    <br/>
                    <br/>
                2.	Speicherdauer
                    <br/>
                a.	Daten von Bewerberinnen und Bewerbern werden im Falle einer Absage spätestens nach 3 Monaten gelöscht - sofern eine längere Speicherung nicht aufgrund etwaiger Beweispflichten im Rahmen eines gerichtlichen Verfahrens bis zum Abschluss eines solchen Verfahrens notwendig ist.
                Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt in der Rechtsverteidigung bzw. -durchsetzung.
                    <br/>
                b.	Für den Fall, dass Sie in eine weitere Speicherung Ihrer personenbezogenen Daten eingewilligt haben, werden wir Ihre Daten in unseren Bewerber/Interessenten-Pool übernehmen. Eine Löschung erfolgt nach zwei Jahren nach Aufnahme in den Bewerber/Interessenten-Pool.
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie jederzeit nach Art. 7 Abs. 3 DSGVO durch Erklärung uns gegenüber mit Wirkung für die Zukunft widerrufen.
                    <br/>
                c.	Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir Ihre bei der Bewerbung übermittelten Daten in Ihrer Personalakte zum Zwecke des üblichen Organisations- und Verwaltungsprozesses – dies natürlich unter Beachtung der weitergehenden rechtlichen Verpflichtungen.
                Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.
                    <br/>
                    <br/>
                3.	Kategorien personenbezogener Daten die verarbeitet werden und Datenquelle
                Wir verarbeiten die von Ihnen im Zusammenhang im Rahmen Ihrer Bewerbung zur Verfügung gestellten Daten. Dies können allgemeine Angaben zu Ihrer Person sein (Kontaktdaten, Name, Anschrift), Angaben zum beruflichen Werdegang und zu beruflichen Qualifikationen, Angaben zu Aus- und Weiterbildung.
                    <br/>
                    <br/>
                4.	Empfänger / Kategorien von Empfängern
                Ihre Bewerberdaten werden nach Eingang Ihrer Bewerbung von der Personalabteilung gesichtet. Geeignete Bewerbungen werden dann an den internen Verantwortlichen weitergeleitet. Im Unternehmen haben grundsätzlich nur die Personen Zugriff auf Ihre Daten, die dies für den ordnungsgemäßen Ablauf unseres Bewerbungsverfahrens benötigen.
                    <br/>
                    <br/>
                5.	Wo werden die Daten verarbeitet?
                Die Daten werden ausschließlich in Rechenzentren der Bundesrepublik Deutschland verarbeitet. Eine Übermittlung in ein Drittland oder eine internationale Organisation erfolgt nicht und ist auch nicht geplant.
                    <br/>
                    <br/>
                6.	Keine automatisierte Entscheidungsfindung
                Es findet keine automatisierte Entscheidung im Einzelfall im Sinne des Art. 22 DSGVO statt. Das bedeutet, dass die Entscheidung über Ihre Bewerbung nicht ausschließlich auf einer automatisierten Verarbeitung beruht.
                    <br/>
                    <br/>
                7.	Weitere Informationen
                Es besteht keine gesetzliche Verpflichtung zur Bereitstellung der o.g. Daten. Diese ist jedoch für die Durchführung des Bewerbungsprozesses und den etwaigen Eintritt in ein Beschäftigungsverhältnis mit uns erforderlich. Die Eignung der Bewerber für Tätigkeiten in unserem Unternehmen kann anderenfalls nicht festgestellt werden.
                    <br/>
                    <br/>
                </p>
                <p>
                XI.	Rechte unserer Nutzer (Betroffenenrechte i.S.d. DSGVO)
                    <br/>
                    <br/>
                Sie als Nutzer, deren personenbezogene Daten verarbeitet werden, sind „Betroffene“ i.S.d. DSGVO. Die nachfolgend aufgeführten Rechte stehen Betroffenen gegenüber uns als Verantwortlichem zu:
                    <br/>
                    <br/>
                •	Recht auf Auskunft: Sie haben das Recht Auskunft über die Verarbeitung Ihrer personenbezogenen Daten zu erhalten (Art 15 DSGVO)
                    <br/>
                •	Recht auf Berichtigung: Sie haben des Recht Berichtigung unrichtiger oder Vervollständigung, Ihrer bei uns gespeicherten personenbezogenen Daten, zu verlangen (Art. 16 DSGVO)
                    <br/>
                •	Recht auf Löschung: Sie haben das Recht Löschung, der bei uns gespeicherten personenbezogenen Daten zu verlangen, sofern dem keine gesetzliche Aufbewahrungspflicht entgegensteht (Art. 17 DSGVO)
                    <br/>
                •	Recht auf Einschränkung der Verarbeitung: Sie haben das Recht Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird oder die Verarbeitung unrechtmäßig ist (Art. 18 DSGVO)
                    <br/>
                •	Recht auf Datenübertragbarkeit: Sie haben das Recht auf Datenübertragbarkeit und damit, die sie betreffenden personenbezogenen Daten, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten (Art. 20 DSGVO)
                    <br/>
                •	Recht auf Widerspruch: Sie haben das Recht auf Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten (Art. 21 DSGVO)
                    <br/>
                •	Recht auf Widerruf der Einwilligung: Sie haben das Recht auf Widerruf einer erteilten Einwilligung (Art. 7 DSGVO)
                    <br/>
                •	Recht auf Beschwerde bei der Aufsichtsbehörde: Sie haben das Recht eine Beschwerde bei der zuständigen Aufsichtsbehörde einzulegen (Art. 77 DSGVO). Dies ist in unserem Fall das Bayerische Landesamt für Datenschutzaufsicht in Ansbach: 'https://www.lda.bayern.de/de/index.html'
                    <br/>
                    <br/>

                Sollten Sie noch Fragen, Anregungen oder Kritik zum Thema Datenschutz haben oder betreffend die Erhebung, Verarbeitung und/oder der Nutzung Ihrer personenbezogenen Daten, so freuen wir uns über Ihre Nachricht. Wir bemühen uns Ihnen schnellst möglich zu antworten: kontakt@prostuff.app
                    <br/>
                    <br/>

                Mit Weiterentwicklung unserer Website und der darauf enthaltenen Angebote oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung anzupassen. Die jeweils aktuelle Datenschutzerklärung kann jederzeit in der App oder unter 'https://www.prostuff.app/datenschutz' abgerufen werden.
                    <br/>
                    <br/>
                </p>
                <p>
                XII.	Benutzerkonto löschen
                    <br/>
                    <br/>
                Aus Sicherheitsgründen bitten wir dich, die Löschung deines Kontos per E-Mail zu beantragen.
                Dadurch werden all deine Daten bei PROSTUFF gelöscht. Danach kannst du nicht mehr auf sie zugreifen.
                    <br/>
                    <br/>

                E-Mail Adresse zur Löschung des Kontos: kontakt@prostuff.app
                    {' '}
                    <br/>
                    <br/>
                </p>
                </div>
    </div>
  );
}

export default DataPrivacy;
